import {SvgIcon} from "@mui/joy";

export function CrateIcon(props) {
    return (
        <SvgIcon {...props} viewBox="0 0 1200 1200">
            <g>
                <path d="m240 0h-240v1200h1200v-1200zm720 780.9-179.1 179.1h-169.7l348.8-348.8zm0 56.562v122.54h-122.54zm-405.38 122.54h-169.7l575.08-575.08v169.7zm405.38-631.64-631.64 631.64h-88.359v-81.34l638.64-638.66h81.359zm-720 493.72v-169.7l412.38-412.38h169.7zm0-226.26v-169.72l186.1-186.1h169.72zm0-226.28v-129.54h129.54zm-200-329.54h160v920h-160zm920 1120h-920v-160h920zm200 0h-160v-920h160zm-160-960h-760v-160h920v160z"/>
                <path d="m1120 80c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m1040 80c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m1120 160c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m1040 160c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m1140 1100c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m1140 1020c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m1060 1100c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m1060 1020c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m120 1120c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m200 1120c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m120 1040c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m200 1040c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m100 100c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m100 180c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m180 100c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
                <path d="m180 180c0 11.047-8.9531 20-20 20s-20-8.9531-20-20 8.9531-20 20-20 20 8.9531 20 20"/>
            </g>
        </SvgIcon>
    );
}
