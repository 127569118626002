const config = {}

config.endpoint = 'https://hd-asset-db-cosmos.documents.azure.com:443/'
config.key = 'kiJhTWWm6QsPuHlrPsqMbIdFL2ZiHoV7vMp5GnSUY7EbjMoRynJE8DmM1fVXxJVrLtQQmryRs7fUACDbeBEoZg=='

config.database = {
  id: 'ToDoList'
}

config.container = {
  id: 'Items'
}

module.exports = config
